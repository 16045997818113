<!-- dashboard.component.html -->
<div class="container mx-auto p-6 bg-white">
  <h1 class="text-2xl font-semibold mb-10">Dashboard</h1>

  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
    <!-- Top 3 Spender Section -->
    <div class="bg-white shadow rounded-lg p-6 col-span-1">
      <h2 class="text-xl font-semibold mb-4">Top 3 spender</h2>
      <div class="space-y-3">
        <!-- Spender Entries -->
        <div class="flex items-center" *ngFor="let spender of topSpendinglist">
          <div class="icon-container mr-4">
            <mat-icon>person</mat-icon>
          </div>
          <div>
            <div class="font-medium">{{ spender.name }}</div>
            <div class="text-gray-600">Spending: {{ spender.amount | currency:'THB' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white shadow rounded-lg p-6" *ngIf="speding">
      <h2 class="text-xl font-semibold mb-4">Spending</h2>
      <div class="mb-6">
        <div class="text-gray-600 mb-1">Total</div>
        <div class="text-lg font-bold">{{speding.totalAll | currency:'THB'}} </div>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div>
          <div class="text-gray-600 mb-1">Today</div>
          <div class="font-medium">{{speding.totalDay | currency:'THB'}} </div>
        </div>
        <div>
          <div class="text-gray-600 mb-1">This Week</div>
          <div class="font-medium">{{speding.totalWeek | currency:'THB'}} </div>
        </div>
        <div>
          <div class="text-gray-600 mb-1">This Month</div>
          <div class="font-medium">{{speding.totalMonth | currency:'THB'}} </div>
        </div>
      </div>
    </div>

    <!-- spending.component.html -->
    <div class="bg-white shadow rounded-lg p-6" *ngIf="participantRegister">
      <h2 class="text-xl font-semibold mb-4">Participant Register</h2>
      <div class="mb-6">
        <div class="text-gray-600 mb-1">Total</div>
        <div class="text-lg font-bold">{{participantRegister.totalAll}}</div>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div>
          <div class="text-gray-600 mb-1">Today</div>
          <span class="font-medium text-lg">{{ participantRegister.totalDay }}</span>
        </div>
        <div>
          <div class="text-gray-600 mb-1">This Week</div>
          <span class="font-medium text-lg">{{ participantRegister.totalWeek }}</span>
        </div>
        <div>
          <div class="text-gray-600 mb-1">This Month</div>
          <span class="font-medium text-lg">{{ participantRegister.totalMonth }}</span>
        </div>
      </div>
    </div>
  </div>
</div>