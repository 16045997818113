import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParticipantComponent } from './participant/participant.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { ReceiptInfoPageComponent } from './receipt-info-page/receipt-info-page.component';
import { LoginComponent } from './login/login.component';
import { RootComponent } from './root/root.component';
import { authGuard, authGuardGuard } from './services/auth.guard';


const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  }, {
    path: '',
    component: RootComponent,
    canActivate: [authGuardGuard],
    children: [
      {
        path: '', component: DashboardComponent
      },
      {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [authGuardGuard],
      }, {
        path: 'participants', component: ParticipantComponent
      }, {
        path: 'info/:id', component: InfoPageComponent
      }, {
        path: 'receipt-info/:id', component: ReceiptInfoPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
