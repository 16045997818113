<!-- participants.component.html -->
<div class="container mx-auto p-6 bg-white shadow-lg rounded">
    <div class="flex justify-between items-center mb-4">
      <h1 class="text-2xl font-semibold">Participants</h1>
      <div class="btn-export">
        <button class="flex items-center px-3 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300" (click)="exportParticipants()">
          <mat-icon>download</mat-icon>
          <span class="ml-2">Export</span>
        </button>
        <button class="flex items-center px-3 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300" (click)="exportDaily()">
          <mat-icon>download</mat-icon>
          <span class="ml-2">Export Daily</span>
        </button>
      </div>

    </div>
  
    <!-- participants.component.html -->
<div class="overflow-x-auto">
    <table class="min-w-full">
      <thead>
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Participant name</th>
          <th class="px-6 pt-6 pb-4">Date register</th>
          <th class="px-6 pt-6 pb-4">Confirming Spending</th>
          <th class="px-6 pt-6 pb-4">Pending request</th>
          <th class="px-6 pt-6 pb-4">Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let participant of participants">
          <tr class="bg-white shadow rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100">
            <td class="px-6 py-4 rounded-tl-lg rounded-bl-lg">{{ participant.name }}</td>
            <td class="px-6 py-4">{{ participant.createdAt }}</td>
            <td class="px-6 py-4">{{ participant.amount | currency: 'THB' }}</td>
            <td class="px-6 py-4">{{ participant.pending }}</td>
            <td class="px-6 py-4 rounded-tr-lg rounded-br-lg">
              <a routerLink="/info/{{participant._id}}" class="text-blue-600 hover:text-blue-800">View info</a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  
  
  <div class="flex justify-center items-center mt-4">
    <div class="flex items-center">
      <mat-icon (click)="changePage(currentPage - 1)" [ngClass]="{'disabled': currentPage === 1}">chevron_left</mat-icon>
      <span>{{ currentPage }}/{{ totalPages }}</span>
      <mat-icon (click)="changePage(currentPage + 1)" [ngClass]="{'disabled': currentPage === totalPages}">chevron_right</mat-icon>
    </div>
  </div>
  