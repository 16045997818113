import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent implements OnInit {
  id: any;
  headerData: any;
  totalData: any;
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ){

  }
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getDetails()
  }
  getDetails(){
    const path = '/backoffice/participant/detail/' + this.id;
    const params = [
      { key: 'skip', value: 1 },
      { key: 'limit', value: 100 },
      { key: 'sortBy', value: 'createdAt' },
      // skip:1
      // limit:10
      // status:all
      // sortBy:createdAt
      // sortOrderBy:asc
    ];
    this.dataService.getDataWithParams(path, params).subscribe((data) => {
      console.log(data);
      this.headerData = data.data.totalHeader
      console.log(data.data.totalHeader);
      
      this.totalData = data.data.totalData

    });
    
  }

}
