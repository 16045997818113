import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
 export class PermissionsService {

 constructor(
 private authService: AuthService,
 public router: Router,
 ) { }

 canActivate(): boolean {
  const currentUser = this.authService.currentUserValue;
 if (currentUser) {
   return true
 } else {
   this.router.navigate(['/login']);
   return false
   }
 }

 }

export const authGuardGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};
export const authGuard: CanActivateFn = (route, state) => {
  return true;
};
