<!-- info-page.component.html -->
<div class="container mx-auto p-6">
    <a routerLink="/participants" class="text-blue-600 hover:text-blue-800">&larr; Participant</a>
  
    <div class="bg-white shadow rounded-lg p-6 mt-6" *ngIf="headerData">
      <h2 class="text-2xl font-semibold mb-4">Participant info</h2>
      <div class="flex items-center mb-4">
        <div class="icon-container mr-4">
          <mat-icon>person</mat-icon>
        </div>
        <div>
          <div class="font-medium text-lg">{{headerData.name}}</div>
          <p>Email: {{headerData.email}}</p>
          <p>Phone number: {{headerData.phoneNumber}}</p>
        </div>
      </div>
      <div class="border-t pt-4">
        <div class="text-gray-600">Total Spending: {{headerData.amount}} THB</div>
        <div class="text-sm text-gray-500">(Spending that has been confirmed)</div>
      </div>
    </div>
  
    <div class="bg-white shadow rounded-lg p-6 mt-6">
      <h2 class="text-2xl font-semibold mb-4">Spending request</h2>
      <div class="overflow-x-auto">
        <table class="min-w-full">
          <thead>
            <tr class="text-left font-bold">
              <th class="px-6 pt-6 pb-4">Request ID</th>
              <th class="px-6 pt-6 pb-4">Date register</th>
              <th class="px-6 pt-6 pb-4">Spending amount</th>
              <th class="px-6 pt-6 pb-4">Card no.</th>
              <th class="px-6 pt-6 pb-4">Status</th>
              <th class="px-6 pt-6 pb-4">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-100" *ngFor="let item of totalData">
              <td class="px-6 py-4">{{item._id | slice:15}}</td>
              <td class="px-6 py-4">{{item.createdAt}}</td>
              <td class="px-6 py-4">{{item.amount}}</td>
              <td class="px-6 py-4">{{item.card}}</td>
              <td class="px-6 py-4">{{item.status}}</td>
              <td class="px-6 py-4">
                <a routerLink="/receipt-info/{{item._id}}" class="text-blue-600 hover:text-blue-800">View info</a>
              </td>
            </tr>
            <!-- Repeat for other spending requests -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  