import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL

  constructor(private http: HttpClient) {}


  getDashboard(){
    return this.http.get(`${this.apiUrl}/backoffice/dashboard`);
  }
  getReceiptDetailById(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/backoffice/participant/receipt/${id}`);
  }
  getDataWithParams(path: string, queryParams: any,): Observable<any> {
    console.log(queryParams);
    let params = new HttpParams();
    for (const param of queryParams) {
      params = params.set(param.key, param.value);
    }
    const requestOptions = {
      params: params
    };
    return this.http
      .get(this.apiUrl + path, requestOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  updateStatus(id: number | string, data: any): Observable<any> {
    const url = `${this.apiUrl}/backoffice/participant/receipt/${id}`; // Adjust the URL as needed
    return this.http.patch(url, data);
  }

  exportData(): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/backoffice/participant/export`, {
      responseType: 'blob' 
    });
  }
  exportDataDaily(): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/backoffice/transaction/export`, {
      responseType: 'blob' 
    });
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.message)) {
      errorMessage = error.error.message.toString();
    } else {
      errorMessage = error.error.message;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => new Error(`${errorMessage}`));

  }
}
