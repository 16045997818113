import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-receipt-info-page',
  templateUrl: './receipt-info-page.component.html',
  styleUrls: ['./receipt-info-page.component.scss']
})
export class ReceiptInfoPageComponent implements OnInit {
  id: any;
  dataView: any;
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private location: Location
  ) {

  }
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getDataDetail();

  }
  getDataDetail() {
    this.dataService.getReceiptDetailById(this.id).subscribe((data: any) => {
      console.log(data);
      this.dataView = data.data
    })
  }
  updateStatus(status: string){

    this.dataService.updateStatus(this.id, {status: status}).subscribe( ( data: any) => {
      console.log(data);
      if (data.statusCode === 200) {
        this.backPage();
      }
      else{
        console.log('err');
        
      }

      
    })
 
  }
  backPage(){
    this.location.back(); 
  }
}
