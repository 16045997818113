<!-- main-layout.component.html -->
<div class="flex  bg-gray-200">
    <!-- Sidebar -->
    <div class="bg-gray-200 w-64 min-h-screen px-4 py-6">
      <div class="mb-4">
        <p class="text-gray-600 font-bold">VISA LIFF</p>
        <p class="text-xs text-gray-500">(Admin system)</p>
      </div>
      <nav class="mt-8">
        <a class="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-100 hover:text-gray-900"
          routerLink="/dashboard">
          <mat-icon>dashboard</mat-icon>
          <span class="ml-3">Dashboard</span>
        </a>
        <a class="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-100 hover:text-gray-900"
          routerLink="/participants">
          <mat-icon>group</mat-icon>
          <span class="ml-3">Participant</span>
        </a>
        <a (click)="isLogout()" class="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-100 hover:text-gray-900">
        <mat-icon>logout</mat-icon>
        <span class="ml-3">Logout</span>
      </a>
        <!-- Add more links as needed -->
      </nav>
    </div>
  
    <!-- Content Area with Navbar -->
    <div class="flex-1 flex flex-col">
  <!-- Navbar -->
  <div class="bg-gray-100 p-4 text-black flex justify-end items-center">
    <div class="flex items-center">
      <mat-icon class="text-black mr-2">account_circle</mat-icon>
      <span>Admin</span>
    </div>
  </div>
  
  
      <!-- Main Content -->
      <div class="flex-1">
        <!-- Your content here -->
        <router-outlet></router-outlet>
        <footer class="bg-gray-200 text-center p-4">
          <div class="text-gray-600">
            © 2023 Copyright
          </div>
        </footer>
      </div>
  
    </div>
  
  
  </div>