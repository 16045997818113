import { Component } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent {

  participants: any;
  currentPage: number = 1; 
  pageSize: number = 10; 
  totalItems: number | undefined;
  totalPages: number | undefined; 

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.getParticipant();
  }
  async getParticipant() {
    const path = '/backoffice/participant/list';
    const skip = (this.currentPage); 
    const params = [
      { key: 'skip', value: skip },
      { key: 'limit', value: this.pageSize },
      { key: 'search', value: '' },
      { key: 'sortBy', value: 'pending' },
      { key: 'sortOrderBy', value: 'desc'}
    ];
    this.dataService.getDataWithParams(path, params).subscribe((data) => {
      console.log(data);
      this.participants = data.data.totalData;
      this.totalItems = data.data.totalCount; 
      this.totalPages = Math.ceil(this.totalItems! / this.pageSize); 

    });
    

  }
  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.getParticipant(); // Fetch the new page data
    }
  }
  exportParticipants(): void {
    this.dataService.exportData().subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      document.body.appendChild(anchor); // Required for Firefox
      anchor.click();
      document.body.removeChild(anchor); // Required for Firefox
      window.URL.revokeObjectURL(url);
    });
  }
  exportDaily(): void {
    this.dataService.exportDataDaily().subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      document.body.appendChild(anchor); // Required for Firefox
      anchor.click();
      document.body.removeChild(anchor); // Required for Firefox
      window.URL.revokeObjectURL(url);
    });
  }
}
