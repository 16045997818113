<!-- receipt-info-page.component.html -->
<div class="container mx-auto p-6" >
    <div class="flex justify-start items-center mb-4">
      <a (click)="backPage()" class="text-blue-600 hover:text-blue-800">&larr; Participant info</a>

    </div>
  
    <h2 class="text-2xl font-semibold mb-4"  *ngIf="dataView">Request ID: {{dataView._id | slice:15}}</h2>
  
    <div *ngIf="dataView" class="flex flex-col lg:flex-row lg:justify-between lg:items-start space-y-6 lg:space-y-0 lg:space-x-6">
      <!-- Receipt Photo Section -->
      <div class="bg-white shadow rounded-lg p-6 flex-1">
        <h3 class="text-lg font-medium mb-4">Receipt photo</h3>
        <img src="{{dataView.image}}" alt="Receipt" class="rounded-lg" />
      </div>
  
      <!-- Spending Info Section -->
      <div class="bg-white shadow rounded-lg p-6 flex-1">
        <h3 class="text-lg font-medium mb-4">Spending info</h3>
        <p class="mb-2"><strong>Date:</strong> {{dataView.createdAt}}</p>
        <p class="mb-2"><strong>Amount:</strong> {{dataView.amount}} THB</p>
        <p class="mb-4"><strong>Card no.:</strong> {{dataView.card}}</p>
        <p class="mb-4"><strong>Status:</strong> {{dataView.status}}</p>
        <div class="flex justify-around" [ngClass]="{'disable-update': dataView.status !=='pending'}">
          <button class="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600" (click)="updateStatus('approved')">Approve</button>
          <button class="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600" (click)="updateStatus('rejected')">Reject</button>
        </div>
      </div>
    </div>
  </div>
  