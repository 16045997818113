import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatIconModule } from '@angular/material/icon';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParticipantComponent } from './participant/participant.component';
import { LoginComponent } from './login/login.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { ReceiptInfoPageComponent } from './receipt-info-page/receipt-info-page.component';
import { RootComponent } from './root/root.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from './services/token.interceptor';
import { PermissionsService } from './services/auth.guard';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ParticipantComponent,
    LoginComponent,
    InfoPageComponent,
    ReceiptInfoPageComponent,
    RootComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
    PermissionsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
