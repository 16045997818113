import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  topSpenders = [
    { name: 'John Doe', amount: 3000000 },
    { name: 'Jane Doe', amount: 2900000 },
    { name: 'Jim Beam', amount: 2800000 }
  ];
  totalSpending = 75000000;
  totalParticipants = 3000;
  todayParticipants = 3;
  thisWeekParticipants = 15;
  thisMonthParticipants = 150;
  topSpendinglist: any;
  participantRegister: any;
  speding: any;
  constructor(
    private dataService: DataService
  ){

  }
  ngOnInit(): void {
    this.getDashboard();
    
  }
  getDashboard(){
    this.dataService.getDashboard().subscribe((data: any) => {
      console.log(data);
      
      this.topSpendinglist = data.data.top
      this.participantRegister = data.data.participantRegister
      this.speding = data.data.spending
    })
  }
}
